
.app-container {
  padding-top: 8vh !important;
}
.table-container {
  margin-top: 5vh;
  border-radius: 30px !important;
  margin-bottom: 2vh;
  padding: 1rem;
  max-width: 40vw;
  display: flex;
  justify-content: center; /* Center the table horizontally */
  align-items: center;
  align-content: center;
}

.table {
  width: 100%;
  border-collapse: collapse; /* Ensure borders are collapsed */
}

.table-row {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.table-cell-bold {
  font-weight: bold;
  text-align: center; /* Center the text horizontally */
  vertical-align: middle; /* Center the text vertically */
  color: #f5f5dc !important;
  border-bottom: 1px solid black !important; /* Add black bottom border */
}

.table-cell {
  text-align: center; /* Center the text horizontally */
  vertical-align: middle; /* Center the text vertically */
  border-bottom: 1px solid black !important; /* Add black bottom border */
}

.table-cell-content {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  
}

.pigeon-container {
  display: flex;
  justify-content: center;
  margin-top: 2vh;
}

.pigeon-image {
  max-width: 20vw;
}

/* Media query for mobile devices */
@media (max-width: 800px) {
  .table-container {
    max-width: 60vw;
  }
  .pigeon-image {
    max-width: 50vw;
  }
  .app-container {
    padding-top: 5vh !important;
  }
}